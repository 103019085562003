<template>
  <ConstructionAlert :title="$t('message.privacy_policy')" />
</template>

<script>
import ConstructionAlert from "@/components/ConstructionAlert.vue";
export default {
  name: "PrivacyPolicy",
  components: {
    ConstructionAlert,
  },
};
</script>

<style>
</style>